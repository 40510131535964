import Un from '../media/Un.jpg';
import Deux from '../media/Deux.jpg';
import Trois from '../media/Trois.jpg';
import Quatre from '../media/Quatre.jpg';
import Cinq from '../media/Cinq.jpg';
import Six from '../media/Six.jpg';


export default [
    {
        title: "",
        description: "",
        urls: Six,

    },
    {
        title: "",
        description: "",
        urls: Deux,
    },
    {
        title: "",
        description: "",
        urls: Trois,
    },
    {
        title: "",
        description: "",
        urls: Quatre,
    },
    {
        title: "",
        description: "",
        urls: Cinq,
    },
    {
        title: "",
        description: "",
        urls: Un,
    },
];