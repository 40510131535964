import React from 'react';
import Navigation from "../components/Navigation";
import OffresPresentation from "../components/Offres/OffresPresentation";
import OffresCarousselle from "../components/Offres/OffresCarousselle";


const Offres = () => {
    return (
        <div className="notreOffres">
            <Navigation />
            <div className="notreOffresContent">
                <OffresPresentation />
                <OffresCarousselle />
            </div>
        </div>
    );
};

export default Offres;

