
// navigation du site

import React from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';

import Home from "./pages/Home";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import PourQuoi from "./pages/PourQuoi";
import Offres from "./pages/Offres";




const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/" element={<Home />}><Home/></Route>
                <Route exact path="/Contact" element={<Contact />}><Contact/></Route>
                <Route exact path="/PourQuoi" element={<PourQuoi />}><PourQuoi/></Route>
                <Route exact path="/Offres" element={<Offres />}><Offres/></Route>
                <Route element={<NotFound />}><NotFound/></Route>
            </Switch>
        </BrowserRouter>
    );
};

export default App;

