import React, {Component} from 'react';
import "../../styles/pages/pourquoi.scss";


class Demarche extends Component {

    render() {
        return (
            <div className="demarche">
                <h3>Démarche</h3>
                <br></br>
                <p>
                    Notre vision : partant de la réflexion selon laquelle exploiter la terre sans conscience n'est que 
                    ruine de l'humanité 
                    (la tournure rabelaisienne n'est nullement fortuite),
                    Actorius agit pour devenir une référence dans sa région en terme de valorisation vertueuse du sol.<br></br>
                        <br></br>
                    Notre mission : agir au quotidien techniquement et commercialement, à court, moyen et long terme avec,
                    pour valeur principale, le respect : le respect de la biodiversité (nous y compris), du consommateur, 
                    du paysan, du partenaire, du transformateur, du conseiller, du salarié, etc.<br></br>
                        <br></br>
                    <h4>Et concrètement, qu’est-ce qu’on fait ?</h4>
                    « Actorius », c’est « celui qui agit » dans la langue de César, alors voici comment nous agissons chez Actorius :<br></br>
                        <br></br>
                    <h4>On fait pousser des graines :</h4>
                    <ul className='list1'>
                        <li>Avec l’aide d’un prestataire justement rémunéré,</li>
                        <li>Avec les conseils d’expert de la chambre d’agriculture et du réseau Bio en Grand Est,</li>
                        <li>En déclinant un assolement réfléchi pour atteindre un équilibre entre qualité, volume et préservation du sol et des ressources naturelles.</li>
                    </ul>
                        <br></br>
                        <h4>On vend nos graines :</h4>
                    <ul className='list1'>
                        <li>A des transformateurs (meunerie, malterie, floconnerie…) passionnés et soucieux du consommateur,</li>
                        <li>A des coopératives ou des négociants qui forment des lots complets pour maximiser les transports,</li>
                        <li>Pour notre compte ou pour le compte d’autres producteurs.</li>
                    </ul>
                        <br></br>
                        <h4>On aide nos collègues bio :</h4>
                    <ul className='list1'>
                        <li>En proposant un accompagnement pour la commercialisation, pour mieux valoriser leur travail,</li>
                        <li>En relayant les acheteurs dans leurs recherches de producteurs en direct, 
                        pour favoriser à terme le consommateur</li>
                    </ul>
                        <br></br>
                        <h4>On agit pour préserver notre environnement (à notre échelle) :</h4>
                    <ul className='list1'>
                        <li>En respectant le cahier des charges du règlement européen (UE) 2018/848 relatif à la 
                        production biologique et à l’étiquetage des produits biologiques (certifié par Ecocert),</li>
                        <li>En ramenant de la vie dans les champs (plantation de 1 km de bande tampon bouchon en 2019, de 970 mètres de haies
                             double ligne en 2022, et encore au moins 1,07 km en 2023, accord avec des apiculteurs),</li>
                        <li>En intégrant une réflexion relative à la décarbonation dans nos décisions stratégiques 
                        (notre audit préparatoire à l’engagement vers le Label Bas Carbone a été initié en 2022).</li>
                    </ul>
                </p>

            </div>
        );
    }
}

Demarche.propTypes = {};

export default Demarche;

