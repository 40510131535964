import React, {Component} from 'react';



class HomePresentation extends Component {

    render() {
        return (
            <div className="HomeTitre">
                <h3>Actorius</h3>
                <p>Actorius est une ferme céréalière fondée en 2016 en Champagne crayeuse, sur des terres familiales.
                    Elle a immédiatement commencé sa conversion vers l'agriculture biologique.
                    Elle est aujourd'hui 100 % certifiée ou engagée. 
                </p>
                
            </div>
        );
    }
}

HomePresentation.propTypes = {};

export default HomePresentation;

