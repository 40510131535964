import React, { useState, useEffect } from "react";
import "../../styles/pages/pourquoi.scss";
import Demarche from "../competences/Demarche.js";
import Partenaires from "../competences/Partenaires.js";
import CreerEtDevelopper from "../competences/CreerEtDevelopper.js";


function Parallax() {
  const [offsetY, setOffsetY] = useState(0);
  const handleScroll = () => setOffsetY(window.pageYOffset);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const renderContent = () => (
    <>
      <div className="Parallax__content__heading">
        <Demarche/>
      </div>
      <div className="Parallax__content__heading">
        <Partenaires/>
      </div>
    </>
  );

  return (
    <section className="Parallax">
        
      <div
        className="Parallax__background-triangles"
        style={{ transform: `translateY(${offsetY * 0.3}px)` } }
      >
      </div>
      
      <div className="Parallax__content">{renderContent()}</div>
    </section>
  );
}

export default Parallax;