import React from "react";
import emailjs from "emailjs-com";
import '../../styles/pages/_contact.scss';



export default function  ContactComponent () {
  
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_1usvivc', 'template_gqkoors', e.target, 'Ywbx5Al4HuKqINfL7')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }

  


  return (
    <div>
      <div className="StyledContactForm">
      <form onSubmit={sendEmail}>
        <label>Objet</label>
        <input type="text" name="subject" />
        <label>Nom</label>
        <input type="text" name="name" />
      <label>Email professionnel</label>
      <input type="email" name="email" />
      <label>Message</label>
      <textarea name="message" />
      <input type="submit" value="Send" />
    </form>
      </div>
    </div>
    
  );
};



