import React from 'react';
import {NavLink} from "react-router-dom";
 

const Navigation = () => {
    return (
        <div className="sidebar">
            <div className="id">
              
                <div className="idContent"/>
                <div className="idContent2"/>
              
                

            </div>

            <div className="navigation">
                <ul>
                    <li>
                        <NavLink exact to="/" activeClassName="navActive">
                            <i className="fas fa-leaf"></i>
                            <span>Accueil</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/PourQuoi" activeClassName="navActive">
                            <i className="fas fa-hiking"></i>
                            <span>Pourquoi Actorius ?</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/Offres" activeClassName="navActive">
                            <i className="fas fa-seedling"></i>
                            <span>Notre offre</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink exact to="/Contact" activeClassName="navActive">
                            <i className="far fa-comments"></i>
                            <span>Contact</span>
                        </NavLink>
                    </li>
                </ul>
            </div>


        </div>
    );
};

export default Navigation;
