import Deux from '../media/Deux.jpg';
import avoineBlanche from '../media/avoineBlanche.jpg';
import orge from '../media/orge.jpg';
import lentilleV from '../media/lentilleV.jpg';
import lentilleC from '../media/lentilleC.jpg';
import sainfoin from '../media/sainfoin.jpg';


export default [
    {
        title: "blé tendre",
        description: "",
        urls: Deux,
    },
    {
        title: "avoine blanche",
        description: "",
        urls: avoineBlanche,
    },
    {
        title: "orge brassicole",
        description: "",
        urls: orge,
    },
    {
        title: "lentilles vertes",
        description: "",
        urls: lentilleV,
    },
    {
        title: "lentilles corail",
        description: "",
        urls: lentilleC,
    },
    {
        title: "sainfoin",
        description: "",
        urls: sainfoin,
    },
];