import React from 'react';
import Navigation from "../components/Navigation";
import ContactComponent from '../components/competences/ContactComponent'


const Contact = () => {
    return (
        <div className="contact">
            <Navigation/>
            <div className="contactContent">
                <ContactComponent />
            </div>
        </div>
    );
};

export default Contact;