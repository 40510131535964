import React, {Component} from 'react';



class OffresPresentation extends Component {

    render() {
        return (

                <div className="offresPres">

                    <h3 className='Nprods'>Nos productions<image className='AB' src="url(../../media/AB.jpg)" alt="label bio"></image></h3>
                    

                    <div className="TexteDePres">
                        <p>blé tendre,<br></br> 
                        orge brassicole,<br></br> 
                        avoine blanche,<br></br> 
                        lentilles vertes,<br></br> 
                        seigle,<br></br> 
                        sarrasin,<br></br> 
                        luzerne,<br></br> 
                        sainfoin.
                        </p>
                    </div>
                    <h3>Nos services</h3>

                    <div className="TexteDePres">
                        <p>Accompagnement à la commercialisation<br></br>
                        (si vous avez un espace de stockage) :<br></br> 
                            commission sur prix de vente HT<br></br> 
                            (entre 1 et 5%, selon volume et contexte)<br></br> 
                            Sourcing – nous contacter
                        </p>
                    </div>
                </div>

        );
    }
}

OffresPresentation.propTypes = {};

export default OffresPresentation;

