import React from 'react';
import Navigation from "../components/Navigation";
import ParallaxPres from '../components/Pourquoi/ParallaxPres';
import Parallax from '../components/Pourquoi/Parallax_effect';


const PourQuoi = () => {
    return (
        <div className="pourquoi">
            <Navigation/>
            <div className="pourquoiContent">

                <Parallax />
                
            </div>
        </div>
    );
};

export default PourQuoi;