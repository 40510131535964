import React from 'react';
import Navigation from "../components/Navigation";
import HomePresentation from "../components/competences/HomePresentation";
import HomeCaroussel from "../components/Home/HomeCaroussel";


const Home = () => {
    return (
        <div className="home">
            <Navigation/>
            <div className="homeContent">

                <HomePresentation />
                <HomeCaroussel />
            </div>
        </div>
    );
};

export default Home;