import React from 'react';

const Partenaires = () => {
    return (
        <div className="partenaires">
            <h3>Nos partenaires</h3>
            <br></br>
            <p>L’observation de la nature (pour ne citer qu’un exemple, les oies sauvages) montre, s’il était encore nécessaire 
                de s’en convaincre, que la coopération permet d’aller plus loin, tout en réduisant l’énergie individuelle à 
                mobiliser pour parvenir à atteindre des objectifs communs.<br></br>
                Actorius a une culture du partenariat avec ses prestataires comme avec ses clients, et plus généralement 
                ses interlocuteurs.<br></br> Il en ressort une création de valeur supérieure (au-delà des chiffres, nous parlons du 
                plaisir de travailler ensemble, de mieux comprendre le métier de l’autre, de chercher des solutions communes) 
                et une rétribution juste du savoir-faire, de l’engagement et du travail de chacun.<br></br>
                <br></br>
                N’hésitez pas à nous contacter, que vous soyez producteurs, acheteurs, coopérateurs, consultants, chercheurs, 
                entrepreneurs, journalistes…
            </p>
        </div>
    );
};

export default Partenaires;
